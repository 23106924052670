import { LoadingButton } from "components/LoadingButton";
import { Spinner } from "components/Spinner";
import React, { useState, FormEvent } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import {
  SectionDescription,
  SectionHeading,
  SectionInfo,
  SectionTitle,
  Separator
} from "components/UI";

interface Props {
  listId: string;
  checkbox: boolean;
}

const NewsletterForm = ({ listId, checkbox }: Props) => {
  const [newsletterPermission, setNewsletterPermission] = useState(false);
  const [newsletterPermissionError, setNewsletterPermissionError] = useState(
    false
  );
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [subscriptionResult, setSubscriptionResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function submitPreprocessing(e: FormEvent<EventTarget>) {
    e.preventDefault();
    if (!email) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!firstName) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (!lastName) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (checkbox && !newsletterPermission) {
      setNewsletterPermissionError(true);
    } else {
      setNewsletterPermissionError(false);
    }
    if (firstName && lastName && email && (!checkbox || newsletterPermission)) {
      try {
        setIsSubmitting(true);
        const response = await fetch("/.netlify/functions/mail-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            listId,
            shouldConfirm: false
          })
        });
        const data = await response.json();
        if (!response.ok) {
          switch (data.reason) {
            case "ALREADY_REGISTERED":
              setSubscriptionResult("ALREADY_REGISTERED");
              break;
            default:
              setSubscriptionResult("ERROR");
              break;
          }
        } else {
          switch (data.msg) {
            case "SUBSCRIBED":
              setSubscriptionResult("SUBSCRIBED");
              break;
            case "PENDING":
              setSubscriptionResult("PENDING");
              break;
            default:
              setSubscriptionResult("SUCCESS");
              break;
          }
        }
      } catch (err) {
        setSubscriptionResult("ERROR");
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <NewsletterContainer>
      {!subscriptionResult && (
        <>
          <SectionHeading>
            <SectionTitle>
              <FormattedMessage
                id="newsletter.title"
                defaultMessage="Newsletter"
              />
            </SectionTitle>
            <SectionInfo>
              <FormattedMessage
                id="newsletter.heading"
                defaultMessage="Iscriviti alla nostra newsletter"
              />
            </SectionInfo>
            <Separator />
            <SectionDescription>
              <FormattedMessage
                id="newsletter.description"
                defaultMessage="Inserisci i dati qui sotto e registrati, per essere aggiornato sulle ultime news!"
              />
            </SectionDescription>
          </SectionHeading>
          <SignUpForm onSubmit={submitPreprocessing}>
            <FormField>
              <label>
                <FormattedMessage
                  id="newsletter.form.name"
                  defaultMessage="Nome"
                />
              </label>
              <SignUpInput
                placeholder="Mario"
                type="text"
                onChange={e => setFirstName(e.target.value)}
                value={firstName}
                hasError={firstNameError}
              />
            </FormField>
            <FormField>
              <label>
                <FormattedMessage
                  id="newsletter.form.surname"
                  defaultMessage="Cognome"
                />
              </label>

              <SignUpInput
                placeholder="Rossi"
                type="text"
                onChange={e => setLastName(e.target.value)}
                value={lastName}
                hasError={lastNameError}
              />
            </FormField>
            <FormField>
              <label>
                <FormattedMessage
                  id="newsletter.form.email"
                  defaultMessage="Email"
                />
              </label>
              <SignUpInput
                placeholder="Email"
                type="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                hasError={emailError}
              />
            </FormField>
            {checkbox && (
              <Checkbox hasError={newsletterPermissionError}>
                <input
                  id="newsletter-checkbox"
                  type="checkbox"
                  checked={newsletterPermission}
                  onChange={() => {
                    setNewsletterPermission(oldState => !oldState);
                    setNewsletterPermissionError(false);
                  }}
                />
                <label htmlFor="newsletter-checkbox">
                  <FormattedMessage
                    id="newsletter.newsletterAgreement"
                    defaultMessage="I agree to receive communications and promotions from Rue Des Mille and to have read the {privacyPolicy}"
                    values={{
                      privacyPolicy: (
                        <a
                          href="https://www.iubenda.com/privacy-policy/63788227"
                          className="iubenda-nostyle no-brand iubenda-embed"
                          title="Privacy Policy "
                        >
                          <FormattedMessage
                            id="newsletter.newsletterAgreement.policyLinkLabel"
                            defaultMessage="privacy policy"
                          />
                        </a>
                      )
                    }}
                  />
                </label>
              </Checkbox>
            )}
            <SignUpButton role="submit" size="large">
              {!isSubmitting ? (
                <FormattedMessage
                  id="newsletter.signup"
                  defaultMessage="Signup"
                />
              ) : (
                <Spinner />
              )}
            </SignUpButton>
          </SignUpForm>
        </>
      )}

      {subscriptionResult === "SUBSCRIBED" && (
        <SuccessContainer>
          <FormattedMessage
            id="newsletter.success"
            defaultMessage="Thanks for subscribing"
          />
        </SuccessContainer>
      )}
      {subscriptionResult === "PENDING" && (
        <SuccessContainer>
          <FormattedMessage
            id="newsletter.pending"
            defaultMessage="Check you email to confirm the subscription"
          />
        </SuccessContainer>
      )}
      {subscriptionResult === "ALREADY_REGISTERED" && (
        <AlreadyRegisteredContainer>
          <FormattedMessage
            id="newsletter.alreadyRegistered"
            defaultMessage="This email is already registered"
          />
        </AlreadyRegisteredContainer>
      )}
      {subscriptionResult === "ERROR" && (
        <ErrorContainer>
          <FormattedMessage
            id="newsletter.error"
            defaultMessage="Something went wrong, retry later"
          />
        </ErrorContainer>
      )}
    </NewsletterContainer>
  );
};

const NewsletterContainer = styled.div`
  display: flex;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.mainColorLight};
  padding-top: 100px;
  text-align: center;
  flex-direction: column;
  ${SectionHeading} {
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
  }
`;

const SuccessContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textLight};
`;

const ErrorContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.error};
`;

const AlreadyRegisteredContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
`;

const SignUpButton = styled(LoadingButton)`
  color: ${({ theme }) => theme.textColorLight};
  background-color: ${({ theme }) => theme.mainColor};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1em;
  overflow: hidden;
  display: block;
  position: relative;
  /* min-width: 200px; */
  height: 40px;
  transition: all 0.3s;
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
  }
`;

const SignUpForm = styled.form`
  text-align: center;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 40px;
  margin: 0 auto;
  margin: 0 auto;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 50px 30px;
  margin-top: 32px;
  background-color: #fff;
`;

const FormField = styled.div`
  display: inline-block;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  &.half {
    width: calc(50% - 20px);
    @media (max-width: 950px) {
      width: calc(100% - 20px);
    }
    @media (max-width: 800px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 600px) {
      width: calc(100% - 20px);
    }
  }
  label {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
    width: 100%;
    display: block;
    text-align: left;
  }
`;

const SignUpInput = styled.input<{ hasError: boolean }>`
  border: none;
  position: relative;
  outline: none;
  border-bottom: 1px solid #949494;
  width: 100%;
  min-height: 40px;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.04em;
  display: block;
  resize: none;
  line-height: 1.4em;

  ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: ${props => props.theme.textColorDark};
    transition: 0.4s;
  }
  &:focus {
    ~ .focus-border {
      width: 100%;
      transition: 0.4s;
    }
  }
  &::placeholder {
    color: #ccc;
  }
  ${({ hasError }) =>
    hasError &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.error};
    `};
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;

  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    opacity: 0;
    position: absolute;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;
    align-items: center;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ hasError }: { hasError: boolean }) =>
        hasError &&
        css`
          border: 2px solid ${({ theme }) => theme.colors.error};
        `};
    }
    & > span {
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: ${({ theme }) => theme.colors.background};
    }
    a {
      color: ${({ theme }) => theme.mainColor};
      text-decoration: none;
      &:hover {
        cursor: pointer;
        font-weight: 600;
        text-decoration: underline;
      }
      span {
        font-size: 11px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.background};
      }
    }
  }
`;

export default NewsletterForm;
