import ContactForm from "components/ContactForm";
import { Link } from "components/Link";
import NewsletterForm from "components/Newsletter";
import Page from "components/Page";
import {
  Email,
  Flexbox,
  Hero,
  HeroImageContainer,
  HeroImg,
  HeroMessageContainer,
  HeroSubtitle,
  HeroTitle,
  Phone,
  Section,
  SectionDescription,
  SectionHeading,
  SectionInfo,
  SectionTitle,
  Separator
} from "components/UI";
import Wrapper from "components/Wrapper";
import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import { PageLocale } from "types";
import styled from "styled-components";
import defaultImage from "../images/studio-dsg-og.png";

interface Props {
  data: {
    page: {
      fields: {
        name: string;
        locales: {
          language: string;
          path: string;
          title: string;
          seo: {
            description: string;
            image: string;
          };
          hero: {
            title: string;
            subtitle: string;
            image: string;
          };
          services: {
            groupTitle: string;
            serviceList: {
              title: string;
              description: string;
              link: string;
            }[];
          }[];
          banner: {
            caption: string;
            title: string;
            subtitle: string;
            image: string;
          };
          contacts: {
            title: string;
            subtitle: string;
            email: string;
          };
        }[];
      };
    };
  };
  pageContext: {
    name: string;
    locale: string;
  };
}

class Home extends React.Component<Props> {
  render() {
    const { data, pageContext } = this.props;
    const home = data.page;
    const currentHome = home.fields.locales.find(
      locale => locale.language === pageContext.locale
    )!;

    return (
      <Page
        title={currentHome.title}
        description={currentHome.seo.description}
        localeCode={pageContext.locale}
        image={currentHome.seo.image}
        pageLocales={data.page.fields.locales.map(
          (locale: any): PageLocale => ({
            code: locale.language,
            url: locale.path
          })
        )}
      >
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "image": [
                  "https://studiodsg.it/${defaultImage}"
                ],
                "@id": "https://studiodsg.it",
                "name": "Studio DSG",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://studiodsg.it/${defaultImage}"
                },
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Viale Europa, 29 ",
                  "addressLocality": "Thiene",
                  "addressRegion": "VI",
                  "postalCode": "36016",
                  "addressCountry": "IT"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 45.7094909,
                  "longitude": 11.490047
                },
                "url": "https://studiodsg.it",
                "telephone": "+390445360277",
                "openingHoursSpecification": [
                  {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday"
                    ],
                    "opens": "8:30",
                    "closes": "18:00"
                  },
                ],
              }
            `}
          </script>
        </Helmet>
        <Hero>
          <HeroImageContainer>
            <HeroImg src={currentHome.hero.image} alt="Studio DSG" />
          </HeroImageContainer>
          <Wrapper>
            <HeroMessageContainer>
              <HeroTitle
                data-aos="fade-right"
                dangerouslySetInnerHTML={{ __html: currentHome.hero.title }}
              />
              <Separator light />
              <HeroSubtitle data-aos="fade-right" data-aos-delay="300">
                {currentHome.hero.subtitle}
              </HeroSubtitle>
            </HeroMessageContainer>
          </Wrapper>
        </Hero>
        <ServicesSection>
          <Wrapper>
            {currentHome.services.map((serviceGroup, groupIndex) => (
              <Flexbox key={groupIndex}>
                <SectionHeading>
                  <SectionInfo>{serviceGroup.groupTitle}</SectionInfo>
                  <Separator />
                </SectionHeading>
                <ServiceList>
                  {serviceGroup.serviceList.map((item, index) => (
                    <Service key={item.title} data-aos="fade-up">
                      <ServiceLink to={item.link}>
                        <ServiceTitle>{item.title}</ServiceTitle>
                        <ServiceDescription>
                          {item.description}
                        </ServiceDescription>
                        <CTA>
                          {index === serviceGroup.serviceList.length - 1 ? (
                            <FormattedMessage
                              id="home.serviceSection.allServices"
                              defaultMessage="Learn more"
                            />
                          ) : (
                            <FormattedMessage
                              id="home.serviceSection.details"
                              defaultMessage="Learn more"
                            />
                          )}
                        </CTA>
                      </ServiceLink>
                    </Service>
                  ))}
                </ServiceList>
              </Flexbox>
            ))}
          </Wrapper>
        </ServicesSection>
        <AboutSection>
          <AboutLink>
            <HeroImageContainer>
              <HeroImg src={currentHome.banner.image} alt="Studio DSG banner" />
            </HeroImageContainer>
            <Wrapper>
              <SectionTitle>{currentHome.banner.caption}</SectionTitle>
              <SectionHeading>
                <SectionInfo>{currentHome.banner.title}</SectionInfo>
                <Separator light />
                <SectionDescription>
                  {currentHome.banner.subtitle}
                </SectionDescription>
                <CTALink to="/consulenza-online/">Scopri di più</CTALink>
              </SectionHeading>
            </Wrapper>
          </AboutLink>
        </AboutSection>
        <ContactsSection>
          <Wrapper>
            <Flexbox>
              <SectionHeading>
                <SectionTitle>Contatti</SectionTitle>
                <SectionInfo>{currentHome.contacts.title}</SectionInfo>
                <Separator />
                <Phone href="tel:0445-360277">0445 360277</Phone>
                <Email
                  href={`mailto:${currentHome.contacts.email}`}
                  target="_top"
                >
                  {currentHome.contacts.email}
                </Email>
                <SectionDescription>
                  {currentHome.contacts.subtitle}
                </SectionDescription>
              </SectionHeading>
              <ContactForm pageName="home" />
            </Flexbox>
          </Wrapper>
        </ContactsSection>
        <NewsletterForm listId="98e7e97d2a" checkbox />
      </Page>
    );
  }
}

export const query = graphql`
  query HomeQuery($name: String!) {
    page: pagesJson(fields: { name: { eq: $name } }) {
      fields {
        name
        locales {
          language
          path
          title
          seo {
            image
            description
          }
          hero {
            title
            subtitle
            image
          }
          services {
            groupTitle
            serviceList {
              title
              description
              link
            }
          }
          banner {
            caption
            title
            subtitle
            image
          }
          contacts {
            title
            subtitle
            email
          }
        }
      }
    }
  }
`;

const CTALink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 20px;

  transition: 0.4s;
  align-self: flex-end;
  color: ${({ theme }) => theme.textColor};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1em;
  &::after {
    content: "→";
    display: inline-block;
    font-size: 14px;
    padding-left: 5px;
    transition: 0.4s;
    font-family: system-ui;
  }
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.mainColor};

    &::after {
      margin-left: 10px;
      margin-right: -10px;
      color: ${({ theme }) => theme.mainColor};
    }
  }
`;

const CTA = styled.span`
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s;
  margin-top: 20px;

  transition: 0.4s;
  align-self: flex-end;
  color: ${({ theme }) => theme.textColor};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1em;
  &::after {
    content: "→";
    display: inline-block;
    font-size: 14px;
    padding-left: 5px;
    transition: 0.4s;
    font-family: system-ui;
  }
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.mainColor};

    &::after {
      margin-left: 10px;
      margin-right: -10px;
      color: ${({ theme }) => theme.mainColor};
    }
  }
`;

const ServicesSection = styled(Section)`
  ${Flexbox} {
    flex-direction: column;
  }
  ${SectionHeading} {
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
  }
`;

const AboutSection = styled(Section)`
  color: ${({ theme }) => theme.textColorLight};
  padding-top: 200px;
  padding-bottom: 200px;
  @media (max-width: 750px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  ${SectionHeading} {
    max-width: 600px;
  }
  ${SectionTitle} {
    color: ${({ theme }) => theme.textColorLight};
  }
  ${SectionInfo} {
    color: ${({ theme }) => theme.textColorLight};
  }
  ${SectionDescription} {
    width: 80%;
    color: ${({ theme }) => theme.textColorLight};

    @media (max-width: 700px) {
      width: 100%;
    }
  }
  ${CTALink} {
    color: ${({ theme }) => theme.textColorLight};
    text-transform: uppercase;
    text-align: right;
    display: block;
    width: 100%;
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.textColorLight};
      &::after {
        color: ${({ theme }) => theme.textColorLight};
      }
    }
  }
`;

const AboutLink = styled.div`
  display: block;
`;

const ContactsSection = styled(Section)`
  ${Flexbox} {
    flex-direction: column;
  }
  ${SectionHeading} {
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
  }
  ${Email} {
    font-size: 14px;

    padding-top: 10px;
    margin-bottom: 40px;
  }
`;

const ServiceLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0;
  padding: 30px 50px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  border: 0px solid #fff;
  position: relative;
  transition: 0.3s all;
  z-index: 1;
  &:hover {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
    border: 10px solid #fff;
    margin: -10px;
    z-index: 10;
  }
  @media (max-width: 750px) {
    padding: 30px 40px;
  }
`;

const ServiceList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  padding-top: 32px;
  li:last-child  {
    ${ServiceLink} {
      background-color: ${({ theme }) => theme.colors.mainColorLight};
      border-color: ${({ theme }) => theme.colors.mainColorLight};
    }
  }
  @media (max-width: 750px) {
    padding-top: 0;
  }
`;

const Service = styled.li`
  padding: 0;
  margin: 0;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  flex: 0 0 50%;
  @media (max-width: 750px) {
    width: 100%;
    padding: 20px 0;
    flex: 0 0 100%;
  }
`;

const ServiceTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
  padding-bottom: 20px;
  letter-spacing: 0.04em;
  line-height: 1.6em;
  color: ${({ theme }) => theme.mainColor};

  @media (max-width: 750px) {
    font-size: 18px;
  }
`;

const ServiceDescription = styled.p`
  font-size: 14px;
  line-height: 1.8em;
`;

export default Home;
