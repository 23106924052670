import { Spinner } from "components/Spinner";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

interface Props {
  size?: "slim" | "normal" | "large";
  isLoading?: boolean;
}

export const LoadingButton = ({
  children,
  size = "normal",
  isLoading = false,
  ...otherProps
}: ButtonHTMLAttributes<any> & Props) => (
  <StyledButton size={size} isLoading={isLoading} {...otherProps}>
    {isLoading ? <Spinner small /> : children}
  </StyledButton>
);

const StyledButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  background-color: ${({ theme, isLoading }) =>
    isLoading ? theme.colors.mainLight : theme.colors.main};
  color: white;
  outline: inherit;
  height: ${props =>
    ({
      slim: "40px",
      normal: "50px",
      large: "50px"
    }[props.size || "normal"])};
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: 600;
  width: 100%;
  max-width: ${props =>
    ({
      slim: "220px",
      normal: "220px",
      large: "100%"
    }[props.size || "normal"])};
  border: none;
  text-transform: uppercase;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.mainHover};
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.colors.disabled};
    opacity: 0.65;
    cursor: auto;
  }
`;
